import axios, { AxiosRequestConfig } from 'axios'
import user from '../store/user'

export const errorHandler = async (e: any, func: any) => {
	console.error('Error in request')
	if (window.location.pathname === '/login') return
	if ((e.response.status || 0) === 401) {
		const refresh = localStorage.getItem('refresh_token')
		try {
			const { data } = await $host.post('api/login/refresh/', { refresh })
			localStorage.setItem('access_token', data.access)
			func()
		} catch (e) {
			user.Authorization = false
		}
	}
}

// Создаем два запроса
// один обычный, второй с токеном
const $host = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: false
})

// Создаем два запроса
// один обычный, второй с токеном
const $authHost = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: false
})

// Создаем функцию добавляющую конфигу токен
const authInterceptor = (config: AxiosRequestConfig<any>) => {
	if (!config || !config.headers) {
		throw Error('Axios: не задан config')
	}
	const token = localStorage.getItem('access_token')
	if(!token){
		return config
	}
	config.headers.Authorization = `Bearer ${token}`
	return config
}

// Навешиваем функцию с изменением конфига
// на "отлавливатель" запросов
$authHost.interceptors.request.use(authInterceptor)

export { $host, $authHost }
