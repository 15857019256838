import { $authHost } from '@/API'
import { makeAutoObservable } from 'mobx'
import { configure } from 'mobx'
import { CompanyInfo } from './Company.interface'
import { reformImage } from '@/utils/imageHandler'
import User from '@/store/user'
import { $fixMe } from '@/types'
configure({ enforceActions: 'never' })

class Company {
	info: CompanyInfo | null = null

	handleCompany = async () => {
		try {
			if (this.info !== null) return
			const { data } = await $authHost.get<CompanyInfo>('api/user_company')
			data.logo = reformImage({ PhotoBytea: data.logo })
			this.info = data
		} catch (e: $fixMe) {
			if (e?.response?.status === 401) {
				if (localStorage.getItem('refresh_token')) {
					await User.handleLogin()
					const { data } = await $authHost.get<CompanyInfo>('api/user_company')
					data.logo = reformImage({ PhotoBytea: data.logo })
					this.info = data
				}
			}
		}
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default new Company()
