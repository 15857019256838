import { BrowserRouter } from 'react-router-dom'

import './App.css'
import { useMount } from './hooks/useMount'
import Theme from './store/Theme'
import { Language } from './utils/langSwitch'
import User from './store/user'
import SettingsCollectSystem from './store/Settings/SettingsCollectSystem/SettingsCollectSystem'
// import Company from './store/Company/Company'
import ModalContainer from 'react-modal-promise'
import { Suspense, lazy, useEffect, useState } from 'react'
import { FetchStages } from './types'
import { notification } from 'antd'
import Company from './store/Company/Company'
import PageLoader from './components/PageLoader'
const ScreenSplit = lazy(() => import('./components/ScreenSplit/ScreenSplit'))
const AppRouter = lazy(() => import('./components/AppRouter'))

const App = () => {
	const [api, context] = notification.useNotification()
	const [isLoading, setIsLoading] = useState(FetchStages.waiting)

	const checkServer = () => {
		const isFrontDev =
			window.location.href.includes('dev.') ||
			window.location.href.includes('localhost')
		const isApiDev = process.env.REACT_APP_API_URL?.includes('dev.')
		const isWSDev = process.env.REACT_APP_WEBSOCKET_URL?.includes('dev.')

		if (!(isApiDev && isFrontDev && isWSDev)) {
			api['error']({
				message: 'Ошибка!',
				description:
					'Неверный сервер, версия для разработчиков пересекается с пользовательской'
			})
		}
	}

	console.time()

	useMount(() => {
		firstFetch()
		checkServer()
	})

	useEffect(() => {
		setInterval(() => {
			const date = new Date()
			if (date.getHours() === 14) {
				window.location.href = window.location.href
			}
		}, 1800000)
	}, [])

	const firstFetch = async () => {
		// Handlers
		setIsLoading(FetchStages.start)
		User.handleLogin()
		Company.handleCompany()
		Theme.handleThemeChange()
		Language.handleLanguage()
		SettingsCollectSystem.HandleReduceCollect()
		setIsLoading(FetchStages.end)
	}
	return (
		// <AdBanner>
		<Suspense fallback={<PageLoader />}>
			{isLoading === FetchStages.start || isLoading === FetchStages.waiting ? (
				<PageLoader />
			) : (
				<ScreenSplit>
					<BrowserRouter>
						<AppRouter />
						{context}
						<ModalContainer />
					</BrowserRouter>
				</ScreenSplit>
			)}
		</Suspense>
		// </AdBanner>
	)
}
export default App
