import Company from '@/store/Company/Company'
import { observer } from 'mobx-react-lite'
import { ComponentProps, FC, ImgHTMLAttributes } from 'react'

const Logotype: FC<ComponentProps<'img'>> = props => {
	if (Company && Company.info?.logo) {
		return (
			<img {...props} src={Company.info.logo} alt={props.alt || 'logotype'} />
		)
	}
	return <></>
}

export default observer(Logotype)
